<template>
    <div :class="$vuetify.breakpoint.mobile ? '' : 'mb-9'">
        <v-app-bar
            color="white"
            dark
            fixed
            :bottom="$vuetify.breakpoint.mobile"
            flat
            width="100vw"
            height="60px"
        >
            <v-app-bar-nav-icon class="d-lg-none mr-3" x-large @click.stop="closeDrawer">
                <v-row justify="center">
                    <v-col cols="12" class="px-0 py-0 my-0 mx-0" align="center">
                        <v-icon color="#ac2f37">
                            mdi-dots-horizontal
                        </v-icon>
                    </v-col>
                    <v-col cols="12" class="px-0 py-0 my-0 mx-0 caption black--text" align="center">
                        Μενού
                    </v-col>
                </v-row>
            </v-app-bar-nav-icon>
            <div style="display: contents;" class="clickable">
                <img v-if="!$vuetify.breakpoint.mobile" width="70px" contain class="main-logo" :src="require('@/assets/main_logo.png')" @click="$router.push({name: 'News'})">
                <span v-if="$vuetify.breakpoint.mobile" style="text-align: end;" @click="$router.push({name: 'News'})">
                    <div>
                        <v-icon large class="mr-2" color="#ac2f37">mdi-home</v-icon>
                    </div>
                    <div class="black--text">
                        Αρχική
                    </div>
                </span>
            </div>
            <v-tabs
                centered
                class="d-none d-lg-block"
            >
                <v-tabs-slider style="display: none;" class="custom-orange" />
                <v-tab v-for="(item, index) in navItems" :key="index" :to="`/${item.link}`" class="text-h6">
                    <span>{{ item.name }}</span>
                </v-tab>
                <!-- <v-menu
                    transition="slide-y-transition"
                    bottom
                    offset-y
                    rounded="b-xl"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-tab class="text-h6" v-bind="attrs" v-on="on">
                            <span>{{ navItems[navItems.length - 1].name }}</span>
                        </v-tab>
                    </template>
                    <v-list>
                        <v-list-item
                            v-for="(subItem, i) in navItems[navItems.length - 1].subItems"
                            :key="i"
                            :to="`/${subItem.link}`"
                        >
                            <span class="text-h6 custom-orange-text">{{ subItem.name }}</span>
                        </v-list-item>
                    </v-list>
                </v-menu> -->
            </v-tabs>
            <img v-if="!$vuetify.breakpoint.mobile" width="70px" contain class="main-logo" :src="require('@/assets/main_logo.png')" @click="$router.push({name: 'News'})">
        </v-app-bar>
        <v-navigation-drawer
            v-model="drawer"
            app
            temporary
            disable-resize-watcher
            width="80vw"
            color="white"
            style="z-index: 101;"
        >
            <v-list
                nav
                dense
                class="text-center"
            >
                <v-row>
                    <v-col cols="12" align="start">
                        <v-icon v-if="secondaryOptions" large class="custom-orange-text" @click="secondaryOptions = false">
                            mdi-arrow-left
                        </v-icon>
                    </v-col>
                </v-row>
                <v-list-item-group
                    v-for="(item, index) in getNavItems()"
                    :key="index"
                    active-class="text--accent-4"
                >
                    <v-list-item class="border-bottom">
                        <v-list-item-title class="black--text text-h6" @click="item.name === 'Διοργάνωση' ? setMenu() : menuActionClick(item.link)">
                            {{ item.name }}
                        </v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
                <!-- <v-list-item class="border-bottom justify-center">
                    <v-icon large class="custom-orange-text" @click="openSocial('facebook')"> mdi-facebook</v-icon>
                    <v-icon large class="custom-orange-text mx-5" @click="openSocial('instagram')"> mdi-instagram</v-icon>
                    <v-icon large class="custom-orange-text mr-5" @click="openSocial('youtube')"> mdi-youtube </v-icon>
                </v-list-item> -->
            </v-list>
        </v-navigation-drawer>
    </div>
</template>

<script>
    export default {
        name: 'NavBar',
        data() {
            return {
                drawer: false,
                secondaryOptions: false,
                assetsUrl: process.env.VUE_APP_GOOGLE_STORAGE_API,
                navItems: [
                    {
                        name: 'Νέα',
                        link: 'News'
                    }
                    // {
                    //     name: 'Βαθμολογία',
                    //     link: 'Standings'
                    // },
                    // {
                    //     name: 'Match Center',
                    //     link: 'Schedule'
                    // },
                    // {
                    //     name: 'Ομάδα',
                    //     link: 'Teams'
                    // },
                    // {
                    //     name: 'Τίτλοι',
                    //     link: 'Stats'
                    // },
                    // {
                    //     name: 'Γήπεδο',
                    //     link: 'Courts'
                    // },
                    // {
                    //     name: 'Ακαδημίες',
                    //     link: 'Profile'
                    // },
                    // {
                    //     name: 'Σύλλογος',
                    //     link: '',
                    //     subItems: [
                    //         {
                    //             name: 'Εγγραφή',
                    //             link: 'Register'
                    //         },
                    //         {
                    //             name: 'Φιλοσοφία',
                    //             link: 'Philosophy'
                    //         },
                    //         {
                    //             name: 'Κανονισμός',
                    //             link: 'Regulations'
                    //         },
                    //         {
                    //             name: 'Επικοινωνία',
                    //             link: 'ContactUs'
                    //         }
                    //     ]
                    // }
                ]
            }
        },
        methods: {
            closeDrawer() {
                if (this.secondaryOptions) {
                    this.secondaryOptions = false
                    this.getNavItems()
                    if (!this.drawer) {
                        this.drawer = !this.drawer
                    }
                } else {
                    this.drawer = !this.drawer
                }
            },
            setMenu() {
                this.secondaryOptions = true
            },
            getNavItems() {
                if (this.secondaryOptions) {
                    return this.navItems[7].subItems
                } else {
                    return this.navItems
                }
            },
            menuActionClick(link) {
                if (link === this.$route.name) {
                    this.drawer = false
                } else {
                    this.$router.push({ name: link })
                }
            },
            openSocial(social) {
                if (social === 'facebook') {
                    window.open('https://www.facebook.com/backinthegamegr', '_blank')
                } else if (social === 'instagram') {
                    window.open('https://www.instagram.com/backinthegame_2012/', '_blank')
                } else if (social === 'youtube') {
                    window.open('https://www.youtube.com/@BackIntheGameBasketball', '_blank')
                } else if (social === 'tiktok') {
                    window.open('https://www.tiktok.com/@backinthegame2012', '_blank')
                }
            }
        }
    }
</script>

<style>
.v-list-item--active {
    color: transparent !important;
}
</style>

<style scoped>

.wave {
    display: block;
    position: relative;
    height: 50px;
    width: 100%;
    background: #f2d16b;
    transform: scale(1, 1);
}

.wave::before {
    content: "";
    display: block;
    position: absolute;
    border-radius: 100%;
    width: 100%;
    height: 300px;
    background-color: #f2d16b;
    right: -25%;
    top: 20px;
}

.wave::after {
    content: "";
    display: block;
    position: absolute;
    border-radius: 100%;
    width: 100%;
    height: 310px;
    background-color: #f2d16b;
    left: -25%;
    top: -240px;
    clip-path: ellipse(100% 15% at -15% 100%);
}

.divider-wrapper {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
}

.custom-divider {
    border-color: orange;
    border-width: 1px;
    height: 20px;
    margin: 0 10px 0 10px;
    display: inline-flex;
    min-height: 100%;
    max-height: 100%;
    vertical-align: text-bottom;
}

.v-application .caption {
    font-size: 0.75rem !important;
    letter-spacing: 0.0333333333em !important;
    line-height: 1.25rem;
    font-family: Arial, sans-serif !important;
    text-transform: capitalize;
}

.text-h6 {
    font-size: 1.25rem !important;
    letter-spacing: 0.0125em !important;
    line-height: 2rem;
    font-family: Arial, sans-serif !important;
}

.v-overlay__scrim {
    opacity: 0 !important;
}

.show-ball {
    color: orange !important;
}

.v-tabs {
    width: 50% !important;
}

.v-tab {
    font-weight: bolder;
    font-size: 15px;
    color: black !important;
    text-transform: none !important;
}

.v-tab::before {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    background-color: transparent;
}

.main-logo {
    align-self: self-start;
}

.menu-item:hover {
    color: #f7941e;
    cursor: pointer !important;
}

.v-navigation-drawer--absolute {
    width: 100vw !important;
    height: auto;
}

.v-list-item .v-list-item__title,
.v-list-item .v-list-item__subtitle {
    line-height: 3.7;
    align-self: center;
    font-size: 1.5rem;
    color: orange;
    font-weight: bolder;
    opacity: 1 !important;
}
</style>
