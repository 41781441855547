import Vue from 'vue'
// import { WebHistory, Router } from 'vue-router'
import Router from 'vue-router'

Vue.use(Router)

const routes = [
    {
        path: '/',
        redirect: '/News'
    },
    {
        path: '/News',
        component: () => import('@/routes/News'),
        name: 'News',
        meta: 'Νέα'
    },
    {
        path: '/Article/:id',
        component: () => import('@/routes/Article'),
        name: 'Article',
        meta: 'Άρθρο'
    }
]

const scrollBehavior = (to, from, savedPosition) => {
    const scrollpos = { left: 0, top: 0 }
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(scrollpos)
        }, 600) // transition just before 600ms
    })
}

const router = new Router({
    // history: new WebHistory(),
    scrollBehavior,
    routes
})

router.beforeEach((to, from, next) => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    return next()
})

export default router
