<template>
    <v-container fluid class="px-0 mx-0 py-0 my-0" style="position: sticky; top: 0; z-index: 10;">
        <v-row justify="center" class="custom-header mx-0 px-0">
            <v-col cols="12" align="start">
                <v-row no-gutters align="center" justify="center">
                    <v-col cols="6" class="py-0 my-0">
                        <span class="custom-orange-text">{{ title }}</span>
                    </v-col>
                    <v-col cols="6" class="py-0 my-0" align="end">
                        <img width="60px" contain class="main-logo" :src="require('@/assets/main_logo.png')" @click="$router.push({name: 'News'})">
                    </v-col>
                    <!-- <v-col cols="12" align="start">
                        <v-icon class="custom-orange-text" @click="openSocial('facebook')"> mdi-facebook</v-icon>
                        <v-icon class="custom-orange-text mx-5" @click="openSocial('instagram')"> mdi-instagram</v-icon>
                        <v-icon class="custom-orange-text mr-5" @click="openSocial('youtube')"> mdi-youtube </v-icon>
                    </v-col> -->
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        props: {
            title: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                assetsUrl: process.env.VUE_APP_GOOGLE_STORAGE_API
            }
        },
        methods: {
            openSocial(social) {
                if (social === 'facebook') {
                    window.open('https://www.facebook.com/backinthegamegr', '_blank')
                } else if (social === 'instagram') {
                    window.open('https://www.instagram.com/backinthegame_2012/', '_blank')
                } else if (social === 'youtube') {
                    window.open('https://www.youtube.com/@BackIntheGameBasketball', '_blank')
                } else if (social === 'tiktok') {
                    window.open('https://www.tiktok.com/@backinthegame2012', '_blank')
                }
            }
        }
    }
</script>

<style scoped>
.custom-header {
    font-weight: 600;
    font-size: 23px;
    background-color: white;
    color: orange;
    height: 80px;
}

.custom-divider {
    border-color: orange;
    border-width: 1px;
    height: 20px;
    margin: 0 10px 0 10px;
}
</style>
